import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Avatar,
  Modal,
  TextField,
  Button,
} from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import CardItem from "../components/CardItem"; // Ensure this is the correct path
import AuthGuard from "../authen/AuthGuard"; // Import AuthGuard
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { isTokenExpired } from "../utils/authUtils";
import { useNavigate } from "react-router-dom";
const MainAccountComponent = ({ setStep, selectedAccount, pockets }) => {
  // const [pockets, setPockets] = useState([
  //   {
  //     id: "1",
  //     type: "Main Pocket",
  //     accountNumber: "111-111-111",
  //     balance: "$5000",
  //   },
  //   {
  //     id: "2",
  //     type: "Basic Saving",
  //     accountNumber: "222-222-222",
  //     balance: "$1200",
  //   },
  //   {
  //     id: "3",
  //     type: "Wealth",
  //     accountNumber: "333-333-333",
  //     balance: "$15000",
  //   },
  // ]);
  const [name, setName] = useState("Mate");
  const [emoji, setEmoji] = useState("😀");
  const [newPocketData, setNewPocketData] = useState({
    parentAccountNo: "",
    productId: "",
  });
  const [exceedMinHeight, setExceedMinHeight] = useState(false);
  const token = localStorage.getItem("access_token");
  const navigate = useNavigate();

  console.log(selectedAccount.account_number)
  console.log(pockets)
  useEffect(() => {
    if (!token || isTokenExpired(token)) {
      navigate('/logout');
    }
  }, [navigate, token]);
  useEffect(() => {
    const storedName = localStorage.getItem("customer_name");
    if (storedName) {
      setName(storedName);
    }
    // Array of emojis
    const emojis = ["😀", "😁", "😂", "😊", "😍", "😎", "😇", "🥳", "🤩", "😄"];
    // Randomly select an emoji
    const randomEmoji = emojis[Math.floor(Math.random() * emojis.length)];
    setEmoji(randomEmoji);

    if (pockets.length >= 7) {
      setExceedMinHeight(true);
    }
  }, []);

  const handleTransfer = () => {
    setStep(6); // Navigate to the "Transfer" page
  };

  const handleCreateSubAccount = () => {
    setStep(8);
  };
  return (
    <>
      {exceedMinHeight && (
        <Box sx={{ background: "linear-gradient(135deg, #4e2a84, #000)", height: "100%", width: "100%" }}>
          {/* Greeting Section */}
          <Box padding={2}>
            <Typography variant="h6" gutterBottom>
              Main Account, {selectedAccount.account_number} ! {emoji}
            </Typography>
          </Box>

          {/* Account List */}
          <Box padding={2}>
            <Box display="flex" alignItems="center" marginBottom={2}>
              <Typography variant="h6" gutterBottom style={{ marginRight: "8px" }}>
                Your Pockets
              </Typography>
              <AddCircleRoundedIcon
                style={{
                  fontSize: 40,
                  color: "#FFFFFF",
                  cursor: "pointer",
                  position: "relative",
                  top: "-2px",
                }}
                onClick={() => handleCreateSubAccount()}
              />
            </Box>
            <List>
              {pockets.length > 0 ? (
                pockets.map((pocket) => (
                  <div key={pocket.account_number}>
                    <ListItem
                      button
                      onClick={() => handleTransfer(pocket)}
                      component="div"
                    >
                      <ListItemIcon>
                        <Avatar style={{ backgroundColor: "#4e2a84" }}>
                          <AccountBalanceIcon />
                        </Avatar>
                      </ListItemIcon>
                      <ListItemText
                        primary={`${pocket.account_type}`}
                        secondary={
                          <Typography style={{ color: "white" }}>
                            {`Account No: ${pocket.account_number} | Balance: ${pocket.balance}`}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </div>
                ))
              ) : (
                <Typography style={{ color: "white" }}>No pockets available</Typography>
              )}
            </List>
          </Box>
        </Box>
      )}

      {!exceedMinHeight && (
        <Box>
          {/* Greeting Section */}
          <Box padding={2}>
            <Typography variant="h6" gutterBottom>
              Main Account, {selectedAccount.account_number} ! {emoji}
            </Typography>
          </Box>

          {/* Account List */}
          <Box padding={2}>
            <Box display="flex" alignItems="center" marginBottom={2}>
              <Typography variant="h6" gutterBottom style={{ marginRight: "8px" }}>
                Your Pockets
              </Typography>
              <AddCircleRoundedIcon
                style={{
                  fontSize: 40,
                  color: "#FFFFFF",
                  cursor: "pointer",
                  position: "relative",
                  top: "-2px",
                }}
                onClick={() => handleCreateSubAccount()}
              />
            </Box>
            <List>
              {pockets.length > 0 ? (
                pockets.map((pocket) => (
                  <div key={pocket.account_number}>
                    <ListItem
                      button
                      onClick={() => handleTransfer(pocket)}
                      component="div"
                    >
                      <ListItemIcon>
                        <Avatar style={{ backgroundColor: "#4e2a84" }}>
                          <AccountBalanceIcon />
                        </Avatar>
                      </ListItemIcon>
                      <ListItemText
                        primary={`${pocket.account_type}`}
                        secondary={
                          <Typography style={{ color: "white" }}>
                            {`Account No: ${pocket.account_number} | Balance: ${pocket.balance}`}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </div>
                ))
              ) : (
                <Typography style={{ color: "white" }}>No pockets available</Typography>
              )}
            </List>
          </Box>
        </Box>
      )}
    </>
  );
};

const MainAccount = ({ setStep, selectedAccount, pockets, setPockets }) => (
  <AuthGuard>
    <MainAccountComponent
      setStep={setStep}
      selectedAccount={selectedAccount}
      pockets={pockets}
      setPockets={setPockets}
    />
  </AuthGuard>
);

export default MainAccount;
